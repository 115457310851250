import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      jwnetItems: [],
    };
  },
  methods: {
    // 特別管理産廃用、廃棄物種類選択肢取得API
    getJwnetItemTypeApiForSpecial() {
        const params = new URLSearchParams();
        params.append("isSpecial", true);
        return axios
          .get(API_URL.DOMAIN.JWNET_ITEM_TYPE + '?' + params.toString())
          .then((res) => {
            this.specialJwnetItems = res.data.jwnetItems;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    // 通常産廃用、廃棄物種類選択肢取得API
    getJwnetItemTypeApiForCommon() {
      const params = new URLSearchParams();
      params.append("isSpecial", false);
      return axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE + '?' + params.toString())
        .then((res) => {
          this.commonJwnetItems = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
  },
  }
};
