<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="許可証一覧" />
    <AppSideMenu />
    <main class="l-main">
      <!-- 検索フォーム ============================================================ -->
      <div>
        <div class="c-searchMenu__wrap">
          <div v-if="isDisplaySearch" class="c-searchMenu">
            <div class="l-flex">
              <div v-if="shorten().length === 0">
                <div class="title icon">条件なし</div>
              </div>
              <template v-else v-for="(s, index) in shorten()">
                <div class="title" :class="[{ icon: index === 0 }]" :key="'shorten-' + index">
                  {{ s.key }}<div class="data"> : {{ s.value }}</div>
                </div>
              </template>
            </div>
            <div class="l-flex">
              <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
            </div>
          </div>
        </div>
        <div class="l-full-white l-area">
          <div class="l-box l-flex">
            <h3 class="c-lead-s-black icon">検索</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols search-wrap">
                <div>
                  <span class="text-label">廃棄物区分</span>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols l-area">
                      <div class="c-radio" v-for="wt in wasteTypeOpt" :key="wt.id">
                        <input
                          :id="'waste-type-' + wt.id"
                          type="radio"
                          :value="wt.id"
                          v-model="searchParam.wasteTypeId"
                        />
                        <label class="c-radio__label" :for="'waste-type-' + wt.id">
                          <span class="c-radio__box"></span>
                          {{ wt.name }}
                        </label>
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
            <div class="c-inputWrap" v-if="searchParam.wasteTypeId == wasteType.INDUSTRIAL_WASTE">
              <div class="c-inputWrap__items__cols search-wrap">
                <div v-if="!isCollector">
                  <span class="text-label">企業名</span>
                  <InputText
                      :value.sync="searchParam.companyName"
                      inputType="text"
                      placeholder="企業名"
                      :validation="validateMaxLen"
                      :validateArg="100"
                  >
                  </InputText>
                </div>  
                <div v-for="(mn, index) in searchParam.municipalityNameList" :key="`mn-${index}`">
                  <span class="text-label">
                    <p v-if="index === 0">自治体名</p>
                  </span>
                  <div class="l-area l-flex">
                    <InputText
                        :value.sync="mn.name"
                        inputType="text"
                        placeholder="自治体名"
                        :validation="validateMaxLen"
                        :validateArg="100"
                    >
                    </InputText>
                    <span
                      v-if="index > 0"
                      class="c-btn-icon delete secondary small l-item-l"
                      @click="removeMunicipalityName(mn)" />
                    <div
                      v-if="index === searchParam.municipalityNameList.length - 1 && searchParam.municipalityNameList.length < 5"
                      class="c-btn secondary add sm l-item-l"
                      @click="addMunicipalityName"
                    >
                      自治体名追加
                    </div>
                  </div>  
                </div>   
              </div>
            </div>
            <div class="c-inputWrap" v-else>
              <div class="c-inputWrap__items__cols search-wrap">
                <div v-if="!isCollector">
                  <span class="text-label">企業名</span>
                  <InputText
                      :value.sync="searchParam.companyName"
                      inputType="text"
                      placeholder="企業名"
                      :validation="validateMaxLen"
                      :validateArg="100"
                  >
                  </InputText>
                </div>  
                <div v-for="(lcm, index) in searchParam.licenceCityNameList" :key="`lcm-${index}`">
                  <span class="text-label">
                    <p v-if="index === 0">市区町村名</p>
                  </span>
                  <div class="l-area l-flex">
                    <InputText
                        :value.sync="lcm.name"
                        inputType="text"
                        placeholder="市区町村名"
                        :validation="validateMaxLen"
                        :validateArg="100"
                    >
                    </InputText>
                    <span
                      v-if="index > 0"
                      class="c-btn-icon delete secondary small l-item-l"
                      @click="removeLicenceCityName(lcm)" />
                    <div
                      v-if="index === searchParam.licenceCityNameList.length - 1 && searchParam.licenceCityNameList.length < 5"
                      class="c-btn secondary add sm l-item-l"
                      @click="addLicenceCityName"
                    >
                      市区町村名追加
                    </div>
                  </div>  
                </div>  
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols search-wrap">
                <div v-if="!isCollector">
                  <span class="text-label">対象許可証</span>
                  <div class="l-flex cols">
                    <div class="c-input" v-for="target in targetLicenceOpt" :key="'target-' + target.id">
                      <div class="c-checkbox">
                        <input
                            :id="'target-' + target.id"
                            type="checkbox"
                            v-model="searchParam.targetLicenceList"
                            :value="target.id"
                        />
                        <label class="c-checkbox__label" :for="'target-' + target.id">
                          <span class="c-checkbox__box"></span>
                          {{ target.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <span class="text-label">ステータス</span>
                  <div class="l-flex cols">
                    <template v-for="status in licenceStatusOpt">
                      <div class="c-input" :key="'status-' + status.id" v-if="!status.onlyCollector || (status.onlyCollector && isCollector)">
                        <div class="c-checkbox">
                          <input
                              :id="'status-' + status.id"
                              type="checkbox"
                              v-model="searchParam.licenceStatusList"
                              :value="status.id"
                          />
                          <label class="c-checkbox__label" :for="'status-' + status.id">
                            <span class="c-checkbox__box"></span>
                            {{ status.name }}
                          </label>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>


            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols search-wrap">
                <div>
                  <span class="text-label">優良判定</span>
                  <div class="l-flex cols">
                    <template v-for="ej in excellentJudgeOpt">
                      <div class="c-input" :key="'ej-' + ej.id">
                        <div class="c-checkbox">
                          <input
                              :id="'ej-' + ej.id"
                              type="checkbox"
                              v-model="searchParam.excellentJudge"
                              :value="ej.id"
                          />
                          <label class="c-checkbox__label" :for="'ej-' + ej.id">
                            <span class="c-checkbox__box"></span>
                            {{ ej.name }}
                          </label>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <div v-if ="searchParam.wasteTypeId == wasteType.INDUSTRIAL_WASTE">
                  <span class="text-label">産業廃棄物管理種別</span>
                  <div class="l-flex cols">
                    <template v-for="jitm in jwnetItemTypeManagementOpt">
                      <div class="c-input" :key="'jitm-' + jitm.id">
                        <div class="c-checkbox">
                          <input
                              :id="'jitm-' + jitm.id"
                              type="checkbox"
                              checked
                              :value="jitm.id"
                              v-model="searchParam.jwnetItemTypeManagementList"
                              @change="changeJwnetItemTypeManagement(jitm)"
                          />
                          <label class="c-checkbox__label" :for="'jitm-' + jitm.id">
                            <span class="c-checkbox__box"></span>
                            {{ jitm.name }}
                          </label>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

              </div>  
            </div>
            <div class="c-inputWrap" v-if="showCommonJwnetItemForIndustrialWaste || searchParam.wasteTypeId == wasteType.COMMON_WASTE">
              <div class="c-inputWrap__items__cols search-wrap">
                <div v-for="(cjit, cIndex) in searchParam.commonJwnetItemTypeIdList" :key="`cjit-${cIndex}`">
                  <span class="text-label">
                    <p v-if="cIndex === 0">産業廃棄物種類</p>
                  </span>
                  <div class="l-area l-flex">
                    <Selectbox
                        class="mid"
                        :value.sync="cjit.value"
                        :validation="validateJustBlank"
                    >
                      <option value="" v-if="searchParam.commonJwnetItemTypeIdList.length === 1">すべて</option>
                      <option
                          v-for="(cji, cjiIndex) in commonJwnetItems"
                          :key="`cjit-cji-${cIndex}-${cjiIndex}`"
                          :value="cji.id"
                      >
                        {{ cji.name }}
                      </option>
                    </Selectbox>
                    <span
                        v-if="cIndex > 0"
                        class="c-btn-icon delete secondary small l-item-l"
                        @click="removeCommonJwnetItemTypeId(cjit)"/>
                    <div
                        v-if="cIndex === searchParam.commonJwnetItemTypeIdList.length - 1 && searchParam.commonJwnetItemTypeIdList.length < 5"
                        class="c-btn secondary sm add l-item-l"
                        @click="addCommonJwnetItemTypeId"
                    >
                      産業廃棄物種類を追加
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap" v-if="showSpecialJwnetItemForIndustrialWaste && searchParam.wasteTypeId == wasteType.INDUSTRIAL_WASTE">
              <div class="c-inputWrap__items__cols search-wrap">
                <div v-for="(sjit, sIndex) in searchParam.specialJwnetItemTypeIdList" :key="`sjit-${sIndex}`">
                  <span class="text-label">
                    <p v-if="sIndex === 0">特別管理産業廃棄物種類</p>
                  </span>
                  <div class="l-area l-flex">
                    <Selectbox
                        class="mid"
                        :value.sync="sjit.value"
                        :validation="validateJustBlank"
                    >
                      <option value="" v-if="searchParam.specialJwnetItemTypeIdList.length === 1">すべて</option>
                      <option
                          v-for="(sji, sjiIndex) in specialJwnetItems"
                          :key="`sjit-sji-${sIndex}-${sjiIndex}`"
                          :value="sji.id"
                      >
                        {{ sji.name }}
                      </option>
                    </Selectbox>
                    <span
                        v-if="sIndex > 0"
                        class="c-btn-icon delete secondary small l-item-l"
                        @click="removeSpecialJwnetItemTypeId(sjit)"/>
                    <div
                        v-if="sIndex === searchParam.specialJwnetItemTypeIdList.length - 1 && searchParam.specialJwnetItemTypeIdList.length < 5"
                        class="c-btn secondary sm add l-item-l"
                        @click="addSpecialJwnetItemTypeId"
                    >
                      特別管理産業廃棄物種類を追加
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="l-container">
          <div class="l-btns l-block-lg">
            <div class="c-btn secondary small" @click="resetSearchHandler">
              条件をリセット
            </div>
            <div class="c-btn primary small" @click="searchHandler">検索</div>
          </div>
        </div>
      </div>

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <div class="c-infoList scroll l-box">
          <div class="c-infoList__row head full">
            <div class="c-infoList__cell" :class="searchedWasteTypeId === wasteType.INDUSTRIAL_WASTE ? 'sm-m' : 'md'">
              <a @click="clickSortChangeHandler">
                <span
                    class="c-infoList__cell__sort"
                    :class="{
                    asc: sortParams.type.toLowerCase() === 'asc',
                    desc: sortParams.type.toLowerCase() === 'desc',
                  }"
                >許可証番号</span>
              </a>
            </div>
            <div class="c-infoList__cell sm">廃棄物区分</div>
            <div class="c-infoList__cell sm" v-if="!isCollector">事業形態</div>
            <div class="c-infoList__cell md" v-if="!isCollector">企業名</div>
            <div class="c-infoList__cell md">廃棄物種類</div>
            <div class="c-infoList__cell md u-flex-center" v-if="searchedWasteTypeId === wasteType.INDUSTRIAL_WASTE">自治体</div>
            <div class="c-infoList__cell md" v-if="searchedWasteTypeId === wasteType.COMMON_WASTE">市区町村</div>
            <div class="c-infoList__cell sm">優良認定</div>
            <div v-if="isCollector" class="c-infoList__cell sm">許可証期限</div>
          </div>

          <!-- 検索結果あり -->
          <template v-if="licenceList.length">
            <div
                v-for="(licence, licenceIndex) of licenceList"
                :key="'licence-' + licenceIndex"
                class="c-infoList__row list full"
            >
              <div class="c-infoList__cell c-tooltip" :class="searchedWasteTypeId === wasteType.INDUSTRIAL_WASTE ? 'sm-m' : 'md'">
                <router-link @click.native="setLicencePage()" :to="licenceDetailUrl(licence)">
                  {{ licence.licenceNo.length > 30 ? (licence.licenceNo.slice(0, 30) + '...') : licence.licenceNo }}
                </router-link>
                <div v-if="licence.licenceNo.length > 30" class="c-tooltip__balloon lh">
                  <span>{{ licence.licenceNo }}</span>
                </div>
                <div v-if="checkExpiredDate(licence.licenceExpiredDate) === 1 && licence.isUpdate === false" class="c-status info-yellow">
                  <div class="c-infoList__popup">
                    <p>許可証期限が迫っています。<br>許可証期限日：{{ licence.licenceExpiredDate | dateFormat }}</p>
                  </div>
                </div>
                <div v-if="checkExpiredDate(licence.licenceExpiredDate) === 2 && licence.isUpdate === false" class="c-status info-red">
                  <div class="c-infoList__popup">
                    <p>許可証期限が過ぎています。<br>許可証期限日：{{ licence.licenceExpiredDate | dateFormat }}</p>
                  </div>
                </div>
              </div>
              <div class="c-infoList__cell sm">
                {{ licence.wasteType.name }}
              </div>
              <div class="c-infoList__cell sm" v-if="!isCollector">
                {{ licence.licenceTypeInfo.name }}
              </div>
              <div class="c-infoList__cell md" v-if="!isCollector">
                {{ licence.companyInfo.name }}
              </div>
              <div class="c-infoList__cell md">
                <CommaText :items="licence.jwnetItemList" arg="name" />
              </div>
              <div class="c-infoList__cell md u-flex-center" v-if="searchedWasteTypeId === wasteType.INDUSTRIAL_WASTE">
                {{ licence.municipalityInfo.name }}
              </div>
              <div class="c-infoList__cell md c-tooltip" v-if="searchedWasteTypeId === wasteType.COMMON_WASTE">
                {{ licence.cityName.length > 30 ? (licence.cityName.slice(0, 30) + '...') : licence.cityName }}
                <div v-if="licence.cityName.length > 30" class="c-tooltip__balloon lh">
                  <span>{{ licence.cityName }}</span>
                </div>
              </div>
              <div class="c-infoList__cell sm">
                <div class="c-excellent" v-if="licence.isExcellent" />
                <div v-else>-</div>
              </div>
              <div v-if="isCollector" class="c-infoList__cell sm">
                {{ licence.licenceExpiredDate | dateJpYMDFormat }}
              </div>
            </div>
          </template>

          <!-- 検索結果なし -->
          <template v-else>
            <div class="c-infoList__row list full">
              <div class="c-infoList__cell">
                検索条件に一致する許可証がありません。
              </div>
            </div>
          </template>
        </div>

        <Pagination v-if="licenceList.length"
            :params="paginationParams"
            :page.sync="paginationParams.page"
            @callback="getLicenceSearchApi(true)"
        />
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import moment from "moment";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import Pagination from "@/components/parts/Pagination.vue";
import CommaText from "@/components/parts/CommaText";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import {DOMAIN} from "@/const";
import search from "./mixins/search";
import callApi from "./mixins/callApi";

export default {
  name: "licence-list",
  components: {
    InputText,
    Selectbox,
    Pagination,
    CommaText,
  },
  mixins: [validation, common, search, callApi],
  data() {
    return {
      licenceList: [],
      isDisplaySearch: false,
      searchedWasteTypeId: 1,
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        column: "licence_no",
        type: "asc",
      },
      // 検索v-model
      searchParam: {
        keyword: "",
        targetLicenceList: [],
        licenceStatusList: [],
        jwnetItemTypeManagementList: [],
        excellentJudge: [],
        wasteTypeId: 1,

      },
      commonJwnetItems: [],
      specialJwnetItems: [],
      showCommonJwnetItemForIndustrialWaste: false,
      showSpecialJwnetItemForIndustrialWaste: false,
      wasteType: DOMAIN.WASTE_TYPE,
      isHighClass: this.$store.getters["userInfo/isHighClass"],
      isCollector: this.$store.getters["userInfo/isCollector"],
      targetLicenceOpt: [
        { id: DOMAIN.TARGET_LICENCE.COLLECTOR, name: "運搬" },
        { id: DOMAIN.TARGET_LICENCE.DISPOSER, name: "処分" },
        { id: DOMAIN.TARGET_LICENCE.ENTRUST, name: "委託契約書にひもづく許可証のみ表示" },
      ],
      licenceStatusOpt: [
        { id: DOMAIN.LICENCE_STATUS.ACTIVE, name: "使用中", onlyCollector: true },
        { id: DOMAIN.LICENCE_STATUS.UPDATING, name: "更新中" },
        { id: DOMAIN.LICENCE_STATUS.WARNING, name: "期限注意" },
        { id: DOMAIN.LICENCE_STATUS.EXPIRED, name: "期限切れ" },
      ],
      wasteTypeOpt: [
        {id: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE, name:"産業"},
        {id: DOMAIN.WASTE_TYPE.COMMON_WASTE, name:"一般"},
      ],
      jwnetItemTypeManagementOpt: [
        {id: DOMAIN.JWNET_ITEM_TYPE_MANAGEMENT.COMMON, name: "普通産業廃棄物"},
        {id: DOMAIN.JWNET_ITEM_TYPE_MANAGEMENT.SPECIAL, name: "特別管理産業廃棄物"},
      ],
      excellentJudgeOpt:[
        {id: 1, name: "有"},
        {id: 0, name: "無"},
      ],
    };
  },
  computed: {
    licenceDetailUrl: function () {
      return function (licence) {
        if (licence.licenceTypeInfo.id === DOMAIN.LICENCE_TYPE.COLLECTOR) {
          if (licence.wasteType.id === this.wasteType.INDUSTRIAL_WASTE)  {
            return `/licence/collect/${licence.licenceId}`;
          } else {
            return `/licence/collect/common/${licence.licenceId}`;
          }
        }
        if (licence.licenceTypeInfo.id === DOMAIN.LICENCE_TYPE.DISPOSER) {
          if (licence.wasteType.id === this.wasteType.INDUSTRIAL_WASTE)  {
            return `/licence/disposal/${licence.licenceId}`;
          } else {
            return `/licence/disposal/common/${licence.licenceId}`;
          }
        }
      };
    },
    checkExpiredDate: function () {
      return function (date) {
        const today = moment();
        const diffDate = moment(date).diff(today, "days");
        if (0 <= diffDate && diffDate <= 30) {
          return 1;
        }
        if (diffDate <= -1) {
          return 2;
        } else {
          return 0;
        }
      };
    },
  },

  methods: {
    initSearch() {
      this.searchParam = {
        wasteTypeId: 1,
        companyName: "",
        municipalityNameList: [
          {
            name: "",
          }
        ],
        licenceCityNameList: [
          {
            name: "",
          }
        ],
        targetLicenceList: [],
        licenceStatusList: [],
        jwnetItemTypeManagementList: [
          1,2
        ],
        excellentJudge: [],
        commonJwnetItemTypeIdList: [
          {
            value: "",
          }
        ],
        specialJwnetItemTypeIdList: [
          {
            value: "",
          }
        ],
      };
      this.changeJwnetItemTypeManagement();
    },
    resetSearchHandler() {
      this.initSearch();
    },
    searchHandler() {
      this.getLicenceSearchApi();
    },
    // 許可証番号ソート変更
    clickSortChangeHandler() {
      this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      this.getLicenceSearchApi(false);
    },

    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    shorten() {
      const result = [];
      const prev = this.$store.getters["searchParamLicence/get"];
      
      if (prev.wasteTypeId) result.push({ key: "廃棄物区分", value: prev.wasteTypeId === this.wasteType.INDUSTRIAL_WASTE ? "産業" : "一般"});
      if (prev.companyName) result.push({ key: "企業名", value: prev.companyName });
      if (prev.municipalityNameList.length) result.push({ key: "自治体名", value: prev.municipalityNameList.map(b => b.name).join("、") });
      if (prev.licenceCityNameList.length) result.push({ key: "市区町村名", value: prev.licenceCityNameList.map(b => b.name).join("、") });
      if (prev.targetLicenceList.length) result.push({ key: "対象許可証", value: prev.targetLicenceList.map(b => this.targetLicenceOpt.filter(opt => opt.id === b)[0].name).join("、") });
      if (prev.licenceStatusList.length) result.push({ key: "ステータス", value: prev.licenceStatusList.map(b => this.licenceStatusOpt.filter(opt => opt.id === b)[0].name).join("、") });
      if (prev.excellentJudge.length) result.push({ key: "優良判定", value: prev.excellentJudge.map(b => this.excellentJudgeOpt.filter(opt => opt.id === b)[0].name).join("、") });
      if (prev.jwnetItemTypeManagementList.length) result.push({ key: "産業廃棄物管理種別", value: prev.jwnetItemTypeManagementList.map(b => this.jwnetItemTypeManagementOpt.filter(opt => opt.id === b)[0].name).join("、") });
      if (prev.commonJwnetItemTypeIdList.length) result.push({ key: "産業廃棄物種類", value: prev.commonJwnetItemTypeIdList.map(b => b.value).join("、") });
      if (prev.specialJwnetItemTypeIdList.length) result.push({ key: "特別管理産業廃棄物種類", value: prev.specialJwnetItemTypeIdList.map(b => b.value).join("、") });

      return result;
    },
    //自治体名を削除
    removeMunicipalityName(mn) {
      this.searchParam.municipalityNameList = this.searchParam.municipalityNameList.filter(
        (m) => m !== mn
      )
    },
    //自治体名を追加
    addMunicipalityName() {
      if(this.searchParam.municipalityNameList.length >= 5) return
      this.searchParam.municipalityNameList.push({
        name: "",
      });
    },
    //市区町村名を削除
    removeLicenceCityName(lcn) {
      this.searchParam.licenceCityNameList = this.searchParam.licenceCityNameList.filter(
        (lc) => lc !== lcn
      )
    },
    //市区町村名を追加
    addLicenceCityName() {
      if (this.searchParam.licenceCityNameList.length >= 5) return
      this.searchParam.licenceCityNameList.push({
        name: "",
      });
    },
    //産業廃棄物種類を削除
    removeCommonJwnetItemTypeId(cjit) {
      this.searchParam.commonJwnetItemTypeIdList = this.searchParam.commonJwnetItemTypeIdList.filter(
        (cji) => cji !== cjit
      )
    },
    //産業廃棄物種類を追加
    addCommonJwnetItemTypeId() {
      if (this.searchParam.commonJwnetItemTypeIdList.length >= 5) return
      this.searchParam.commonJwnetItemTypeIdList.push({
        value: "",
      });
    },
    //特別管理産業廃棄物種類を削除
    removeSpecialJwnetItemTypeId(sjit) {
      this.searchParam.specialJwnetItemTypeIdList = this.searchParam.specialJwnetItemTypeIdList.filter(
        (sji) => sji !== sjit
      )
    },
    //特別管理産業廃棄物種類を追加
    addSpecialJwnetItemTypeId() {
      if (this.searchParam.specialJwnetItemTypeIdList.length >= 5) return
      this.searchParam.specialJwnetItemTypeIdList.push({
        value: "",
      });
    },
    //産業廃棄物管理種別を変更する時
    changeJwnetItemTypeManagement() {
      this.showSpecialJwnetItemForIndustrialWaste = (this.searchParam.jwnetItemTypeManagementList.includes(DOMAIN.JWNET_ITEM_TYPE_MANAGEMENT.SPECIAL)
                                    || !this.searchParam.jwnetItemTypeManagementList.length);
      this.showCommonJwnetItemForIndustrialWaste = (this.searchParam.jwnetItemTypeManagementList.includes(DOMAIN.JWNET_ITEM_TYPE_MANAGEMENT.COMMON)
                                    || !this.searchParam.jwnetItemTypeManagementList.length); 
    },
    setLicencePage() {
      this.$store.commit("searchParamLicence/setLicencePage", this.paginationParams.page);
    },
    addRequestToLocation(requestObj) {
      const param = new URLSearchParams(requestObj)
      history.replaceState(
      {},
      null,
      this.$route.path + '?' + param
    )
    }
  },
  beforeRouteEnter(to, from, next) {
    next( (vm) => {
      if(from.path.includes("/licence")) {
        let page = vm.$store.getters["searchParamLicence/getLicencePage"]
        vm.paginationParams.page = page
      } else {
        // リセットする
        vm.$store.commit("searchParamLicence/setLicencePage", 1);
      }
    })
  },

  beforeCreate() {
    // クエリパラメータのチェック
    if (this.$route.query.licenceStatusList && this.$route.query.targetLicenceList) {
      this.$store.commit("searchParamLicence/set", {
        wasteTypeId: 1,
        showSpecialJwnetItemForIndustrialWaste: true,
        showCommonJwnetItemForIndustrialWaste: true,
        companyName: "",
        municipalityNameList: [
          {
            name: "",
          }
        ],
        licenceCityNameList: [
          {
            name: "",
          }
        ],
        targetLicenceList: this.$route.query.targetLicenceList.split(",").map(s => Number(s)),
        licenceStatusList: this.$route.query.licenceStatusList.split(",").map(s => Number(s)),
        jwnetItemTypeManagementList: [],
        excellentJudge: [],
        commonJwnetItemTypeIdList: [
          {
            value: "",
          }
        ],
        specialJwnetItemTypeIdList: [
          {
            value: "",
          }
        ],
      });
    } else if (this.$route.query.licenceStatusList) {
      this.$store.commit("searchParamLicence/set", {
        wasteTypeId: 1,
        showSpecialJwnetItemForIndustrialWaste: true,
        showCommonJwnetItemForIndustrialWaste: true,
        companyName: "",
        municipalityNameList: [
          {
            name: "",
          }
        ],
        licenceCityNameList: [
          {
            name: "",
          }
        ],
        targetLicenceList: [],
        licenceStatusList: this.$route.query.licenceStatusList.split(",").map(s => Number(s)),
        jwnetItemTypeManagementList: [],
        excellentJudge: [],
        commonJwnetItemTypeIdList: [
          {
            value: "",
          }
        ],
        specialJwnetItemTypeIdList: [
          {
            value: "",
          }
        ],
      });
    }
  },

  mounted() {
    this.searchParam = this.$store.getters["searchParamLicence/get"];
    window.addEventListener("scroll", this.handleScroll);
    this.getLicenceSearchApi(true);
    this.getJwnetItemTypeApiForCommon();
    this.getJwnetItemTypeApiForSpecial();
    this.changeJwnetItemTypeManagement();
  },
};
</script>

<style scoped>
.sm-s {
  width: 18rem;;
}
</style>
