import {API_URL, DOMAIN } from "@/const";
import axios from "axios";

export default {
  methods: {
    // URL生成
    getSearchApiUrl() {
      const params = new URLSearchParams();
      params.append("page", this.paginationParams.page);
      params.append("pageSize", this.paginationParams.pageSize);
      params.append("sort", `${this.sortParams.column}:${this.sortParams.type}`);
      if (this.searchParam.wasteTypeId) params.append("wasteTypeId", this.searchParam.wasteTypeId);
      if (this.searchParam.companyName) params.append("companyName", this.searchParam.companyName);
      if (this.searchParam.municipalityNameList && this.searchParam.wasteTypeId == DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE) {
        this.searchParam.municipalityNameList.forEach((municipalityName) => { 
          if( municipalityName && municipalityName.name) params.append("municipalityNameList", municipalityName.name); 
        });
      }
      if (this.searchParam.licenceCityNameList && this.searchParam.wasteTypeId == DOMAIN.WASTE_TYPE.COMMON_WASTE) {
        this.searchParam.licenceCityNameList.forEach((licenceCityName) => { 
          if( licenceCityName && licenceCityName.name) params.append("licenceCityNameList", licenceCityName.name); });
      }
      if (this.searchParam.targetLicenceList) {
        this.searchParam.targetLicenceList.forEach((targetLicence) => { params.append("targetLicenceList", targetLicence); });
      }
      if (this.searchParam.licenceStatusList) {
        this.searchParam.licenceStatusList.forEach((licenceStatus) => { params.append("licenceStatusList", licenceStatus); });
      }
      if (this.searchParam.jwnetItemTypeManagementList && this.searchParam.wasteTypeId == DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE) {
        this.searchParam.jwnetItemTypeManagementList.forEach((jwnetItemTypeManagement) => { params.append("jwnetItemTypeManagementList", jwnetItemTypeManagement); });
      }
      if (this.searchParam.excellentJudge && this.searchParam.excellentJudge.length == 1) {
        this.searchParam.excellentJudge.forEach((excellent) => { params.append("excellentJudge", excellent); });
      }
      if (
        this.searchParam.commonJwnetItemTypeIdList
        && (this.searchParam.jwnetItemTypeManagementList.includes(DOMAIN.JWNET_ITEM_TYPE_MANAGEMENT.COMMON) || !this.searchParam.jwnetItemTypeManagementList || this.searchParam.wasteTypeId == DOMAIN.WASTE_TYPE.COMMON_WASTE)
      ) {
        this.searchParam.commonJwnetItemTypeIdList.forEach((commonJwnetItemTypeId) => { 
          if (commonJwnetItemTypeId && commonJwnetItemTypeId.value) params.append("commonJwnetItemTypeIdList", commonJwnetItemTypeId.value); });
      }
      if (
        this.searchParam.specialJwnetItemTypeIdList && this.searchParam.wasteTypeId == DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE
        && (this.searchParam.jwnetItemTypeManagementList.includes(DOMAIN.JWNET_ITEM_TYPE_MANAGEMENT.SPECIAL) || !this.searchParam.jwnetItemTypeManagementList)
      ) {
        this.searchParam.specialJwnetItemTypeIdList.forEach((specialJwnetItemTypeId) => { 
          if (specialJwnetItemTypeId && specialJwnetItemTypeId.value) params.append("specialJwnetItemTypeIdList", specialJwnetItemTypeId.value); });
      }

      return API_URL.LICENCE.SEARCH + '?' + params.toString();
    },

    // 許可証検索API 検索結果
    getLicenceSearchApi(isPaging) {
      if (!isPaging) {
        this.paginationParams.page = 1; // 1ページ目に初期化
      }
      axios
        .get(this.getSearchApiUrl())
        .then((res) => {
          this.licenceList = res.data.licenceList;
          this.paginationParams = res.data.searchParams.pagination;
          this.sortParams = res.data.searchParams.sorts[0];
          this.searchedWasteTypeId = this.searchParam.wasteTypeId
          this.$store.commit("searchParamLicence/set", this.searchParam);
          const currentParam = this.$route.query
          const requestObj = { 'page' : parseInt(this.paginationParams.page) }
          this.addRequestToLocation(Object.assign(currentParam, requestObj))
        })
        .catch((err) => {
          this.licenceList = [];
          console.log(err);
        });
    },
  },
};